// Importation ---------------------------------------------------------------
import Swiper from 'swiper/bundle'
import { OBSERVER } from './../main.js'
// ---------------------------------------------------------------------------


export function swiperAlert(options = {}) {

  // Déclaration des propriétés par défaut
  options.container === undefined ? options.container = '[data-swiper="alert-container"]' : options.container
  options.slider === undefined ? options.slider = '[data-swiper="alert"]' : options.slider
  options.slide === undefined ? options.slide = '[data-swiper="alert-slide"]' : options.slide
  options.navigation === undefined ? options.navigation = '[data-swiper="alert-navigation"]' : options.navigation
  options.prev === undefined ? options.prev = '[data-swiper="alert-prev"]' : options.prev
  options.next === undefined ? options.next = '[data-swiper="alert-next"]' : options.next
  options.close === undefined ? options.close = '[data-swiper="alert-close"]' : options.close
  options.close === undefined ? options.close = '[data-swiper="alert-close"]' : options.close

  // Déclarations des variables
  let container = document.querySelector(options.container)

  // Déclarations des fonctions
  const closeAlert = () => document.documentElement.style.setProperty('--alert-height', '0px')

  // Ajout de l événement pour masquer la bannière d'alertes
  OBSERVER.add({
    name: 'alert',
    event: 'click',
    function: closeAlert,
    target: options.close
  })
  OBSERVER.on('alert')

  // Si la page contient le container alert
  if (typeof (container) != 'undefined' && container != null) {

    // Si il y a plus qu'une slide
    if (document.querySelectorAll(options.slide).length > 1) {

      // Création du slider
      window.addEventListener('load', () => {
        window['swiper-alert'] = new Swiper(options.slider, {
          threshold: 10,
          loop: true,
          slidesPerView: 1,
          spaceBetween: 25,
          speed: 500,
          autoplay: {
            delay: 7000,
          },
          pagination: {
            el: '[data-swiper="alert-pagination"]',
            type: 'fraction',
          },
          navigation: {
            prevEl: options.prev,
            nextEl: options.next
          },
          runCallbacksOnInit: true,
          on: {
            init: function(swm){
              let fraction = document.querySelector('[data-swiper="alert-pagination-fraction"]')
              fraction.innerHTML = (swm.realIndex + 1) + '/' + (swm.slides.length - (swm.loopedSlides*2))
            }, //runs callback in initialization
            slideChange: function(swm){
              let fraction = document.querySelector('[data-swiper="alert-pagination-fraction"]')
              fraction.innerHTML = (swm.realIndex + 1) + '/' + (swm.slides.length - (swm.loopedSlides*2))
            } //runs callback in slide change end
          }
        })
      }, false)
    }
  } else {
    document.documentElement.style.setProperty('--alert-height', '0px')
  }
}
