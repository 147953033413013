// Importation ---------------------------------------------------------------
import Swiper from 'swiper/bundle'
import { OBSERVER } from './../main.js'
// ---------------------------------------------------------------------------

export function swiperHomeBanner(options = {}) {
  // Déclaration des propriétés par défaut
  options.slider === undefined ? options.slider = '[data-swiper="home-banner"]' : options.slider
  options.slide === undefined ? options.slide = '[data-swiper="home-banner-slide"]' : options.slide

  // Si il y a plus qu'une slide
  if (document.querySelectorAll(options.slide).length > 1) {

    // Création du slider
    window['home-banner'] = new Swiper(options.slider, {
      loop: true,
      speed: 1000,
      autoplay: {
        delay: 7000,
      },
      slidesPerView: 1,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      pagination: {
        el: '[data-swiper-pagination="home-banner-slider"]',
        type: 'bullets',
        clickable: true
      },
      runCallbacksOnInit: true,
      on: {
        init: function(sw2){
          let fraction = document.querySelector('[data-swiper="home-banner-pagination-fraction"]')
          let index

          if((sw2.realIndex + 1) < 10) {
            index = '0' + (sw2.realIndex + 1)
          } else {
            index = sw2.realIndex + 1
          }

          fraction.innerHTML = '<span>' + index + '</span>'
        }, //runs callback in initialization
        slideChange: function(sw2){
          let fraction = document.querySelector('[data-swiper="home-banner-pagination-fraction"]')
          let index

          if((sw2.realIndex + 1) < 10) {
            index = '0' + (sw2.realIndex + 1)
          } else {
            index = sw2.realIndex + 1
          }

          fraction.innerHTML = '<span>' + index + '</span>'
        } //runs callback in slide change end
      },
    })

    const oneDot = document.querySelector('[data-swiper-pagination="home-banner-slider"] span')
    let dotHeight
    let dotWidth

    const onResize = () => {
      dotHeight = oneDot.offsetHeight
      document.documentElement.style.setProperty('--swiper-home-banner-dot-height', `${dotHeight}px`)
      document.documentElement.style.setProperty('--swiper-home-banner-dot-top', `${dotHeight * window['home-banner'].realIndex}px`)

      dotWidth = oneDot.offsetWidth
      document.documentElement.style.setProperty('--swiper-home-banner-dot-width', `${dotWidth}px`)
      document.documentElement.style.setProperty('--swiper-home-banner-dot-left', `${dotWidth * window['home-banner'].realIndex}px`)
    }

    onResize()

    OBSERVER.add({
      name: 'swiperHomeBanner',
      event: 'resize',
      function: onResize
    })

    OBSERVER.on('swiperHomeBanner')

    window['home-banner'].on('transitionStart', function() {
      document.documentElement.style.setProperty('--swiper-home-banner-dot-top', `${dotHeight * window['home-banner'].realIndex}px`)
    })
    window['home-banner'].on('transitionStart', function() {
      document.documentElement.style.setProperty('--swiper-home-banner-dot-left', `${dotWidth * window['home-banner'].realIndex}px`)
    })
  }
}
